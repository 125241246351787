.joinContainer {
    width: 100%;
    height: 387px;
    background-image: url('../images/joinC.jpg');
    background-size: cover;
    background-position: center;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
    box-sizing: border-box;
}

.joinDet {
    color: #0187A0;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    max-width: 1200px;
    text-align: left;
}

.jHead {
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.joinHead {
    font-family: Poppins, sans-serif;
    font-size: 3rem;
    font-weight: 700;
    line-height: 1.2;
    padding-left: 10%;
}

.jInfo {
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding-right: 10%;
}

.jDetails,
.jBottom {
    font-family: Poppins, sans-serif;
    font-size: 1.375rem;
    font-weight: 600;
    line-height: 1.4;
    margin-bottom: 10px;
}

.firstJ {
    display: flex;
    align-items: center;
}

/* Responsive Design */
@media only screen and (max-width: 768px) {
    .joinDet {
        flex-direction: column;
        text-align: center;
    }

    .jHead, .jInfo {
        width: 100%;
        padding: 0;
    }

    .joinHead {
        font-size: 2rem;
        padding: 0;
        margin-bottom: 50px;    
    }

    .jDetails,
    .jBottom {
        font-size: 1.125rem;
        width: 90%;
        text-align: center;
    }
}

@media only screen and (max-width: 480px) {
    .joinHead {
        font-size: 1.5rem;
        margin-bottom: 50px;
    }

    .jDetails,
    .jBottom {
        font-size: 1rem;
        width: 100%;
    }
}
