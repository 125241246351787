.navbar {
  background: #046b7d;
  color: #fff;
  position: fixed;
  width: 100%;
  z-index: 1000;
  width: 100%;
  height: 135px;
  transition: height 0.3s ease;
  position: relative;
}

.navbar.expanded {
  height: 25vh;
}

.navbar-container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  margin: 0 auto;
  padding: 0 20px;
  height: 100%; 
  position: relative;
}

.logo {
  transition: opacity 0.3s ease;
}

.logo.hidden {
  opacity: 0;
}

.logoPic {
  margin: 5px;
  height: 100px;
  width: 112px; 
  opacity: 2;
  position: absolute;
  top: -10px;
}

.navbar-logo {
  text-decoration: none;
  color: #fff;
  position: absolute;
  left: 20px;
  top: 30px;
  height: 200px;
}

.navbar-links {
  position: absolute;
  display: flex;
  align-items: center;
  gap: 20px;
  height: 100%;
  top: 0px;
  left: 140px;
}

.navbar-links.active {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 37px;
}

.navbar-links a {
  color: #fff;
  text-decoration: none;
  font-size: 1rem;
}

.navbar-toggle {
  display: none;
  top: 100px; 
  margin-left: 25px;
}

.navbar-toggle span {
  display: block;
  width: 25px;
  height: 3px;
  background: #fff;
  margin: 5px;
}

.contact_header {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 20;
  left: 1300px;
  width: 139px;
  height: 36px;
  border-radius: 50px;
  border: 1px solid #C6E070;
}

.contact_header:hover {
  cursor: pointer;
}


.navbar.expanded .navbar-toggle {
  top: 100px; 
  margin-left: 25px;
}

@media screen and (max-width: 1508px) {
  
  .contact_header {
    position: absolute;
    left: 1000px;
  }
}


@media screen and (max-width: 1168px) {
  .navbar-container {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    margin: 0 auto;
    padding: 0 20px;
    height: 100%; 
    position: relative;
  }
  
  .logo img {
    width: 70px;
    height: 70px;
  }
  .contact_header {
    position: absolute;
    left: 600px;
  }
}




@media screen and (max-width: 767px) {
  .navbar-container {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    margin: 0 auto;
    padding: 0 20px;
    height: 100%; 
    position: relative;
  }
  
  .logo img {
    width: 100px;
    height: 100px;
    padding-bottom: 5px;
  }


  .navbar-links {
    display: none;
  }

  .navbar.expanded .navbar-links {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
  }

  .navbar-toggle {
    display: block;
    cursor: pointer;
    position: absolute;
    top: 55px;
    left: 300px;
  }
  .contact_header {
   display: none;
  }
}
