.socialMedia_container {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
}

.socialSq {
    width: 100%;
    max-width: 1479px;
    height: auto;
    border-radius: 30px;
    border: 4px solid #C6E070;
    opacity: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: #F1F2F2;
    padding: 20px;
}

.socialImg {
    height: 300px;
    width: 1300px; 
    object-fit: cover;
    border-radius: 10px;
}

.socialMedia_text {
    width: 100%;
    max-width: 1354px;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;    
    padding: 20px;
}

.socialMedia_text h2 {
    font-family: Poppins;
    font-size: 40px;
    font-weight: 700;
    line-height: 40px;
    text-align: left;
    color: #1C344D;
    margin-bottom: 20px;
}

.socialMedia_text p {
    font-family: Poppins;
    font-size: 30px;
    font-weight: 300;
    line-height: 40px;
    text-align: left;
    margin: 0;
}

@media (max-width: 1125px) {
   
    .socialImg {
        height: 300px;
        width: 800px;
    }
    

    .socialMedia_text h2 {
        font-size: 30px;
        line-height: 36px;
        text-align: center;
    }

    .socialMedia_text p {
        font-size: 20px;
        line-height: 28px;
        text-align: center;
    }
}


@media (max-width: 768px) {
    
    .socialImg {
        height: 300px;
        width: 100%;
        max-width: none;
    }

    .socialMedia_text h2 {
        font-size: 30px;
        line-height: 36px;
        text-align: center;
    }

    .socialMedia_text p {
        font-size: 20px;
        line-height: 28px;
        text-align: center;
    }
}
