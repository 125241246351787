.hfContainer {
    background-color: white;
    display: flex;
    position: relative;
    height: 893px;
    width: 100%;
}

.hfLeft {
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 27%;
    padding-left: 50px;
    top: 100px;
}

.hfRight {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100px;
    
}

.hfHeader {
    color: #0187A0;
    font-family: Poppins;
    font-size: 57px;
    font-weight: 700;
    line-height: 64px;
    letter-spacing: -0.25px;
    text-align: left;

}

.hfBtn {
    background-color:#0187A0 ;
    width: 278px;
    height: 48px;
    top: 7800px;
    left: 147px;
    gap: 0px;
    border-radius: 50px;
    color: white;
}

.hfBtn:hover {
    cursor: pointer;
}

.hfParaf {
    width: 456px;
    height: 148px;
    top: 618px;
    left: 147px;
    gap: 0px;
    font-family: Roboto;
    font-size: 32px;
    font-weight: 400;
    line-height: 37px;
    letter-spacing: 0.25px;
    text-align: left;
    color: black;
}

.firstSq {
    position: absolute;
    width: 645px;
    height: 440px;
    top: 147px;
    left: 640px;
    gap: 0px;
    border-radius: 30px;
    border: 2px solid #0187A0;
    z-index: 1;
    left: 35;
}

.secondSq {
    position: absolute;
    width: 733px;
    height: 487px;
    top: 71px;
    left: 662px;
    gap: 0px;
    border-radius: 30px;
    background: #91C46C96;
    z-index: 2;
}

.thridSq {
    position: absolute;
    width: 758px;
    height: 485px;
    top: 43px;
    left: 696px;
    border-radius: 13px;
    z-index: 3;
}

@media only screen and (max-width: 1521px) {
    .hfContainer {
        background-color: white;
        display: flex;
        position: relative;
        height: 70vh;
        width: 100%;
    }
    .hfLeft {
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 100%;
        padding-left: 100px;
        position: absolute;
        top: 130px;
        left: 50px;
        padding-left: 50px;
    }
    

    .hfHeader {
        font-size: 32px;
        line-height: 38px;
        width: 100%;
        padding-bottom: 15px;
    }

    .hfParaf {
        font-size: 20px;
        line-height: 26px;
        width: 100%;
        width: 500px;
    }

    .hfBtn {
        width: 220px;
        height: 40px;
    }

    .firstSq {
        position: absolute;
        width: 525px;
        height: 390px;
        top: 107px;
        left:700px;
        gap: 0px;
        border-radius: 30px;
        border: 2px solid #0187A0;
        z-index: 1;
        left: 35;
    }
    
    .secondSq {
        position: absolute;
        width: 573px;
        height: 417px;
        top: 40px;
        left: 722px;
        gap: 0px;
        border-radius: 30px;
        background: #91C46C96;
        z-index: 2;
    }
    
    .thridSq {
        position: absolute;
        width: 610px;
        height: 415px;
        top: 10px;
        left: 748px;
        gap: 0px;
        border-radius: 13px;
        z-index: 3;
    }
    

}

@media only screen and (max-width: 1371px) {
    .hfContainer {
        background-color: white;
        display: flex;
        position: relative;
        height: 50vh;
        width: 100%;
    }
    .hfLeft {
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 350px;
        padding-left: 100px;
        position: absolute;
        top: 130px;
        left: 50px;
        padding-left: 50px;
    }
    

    .hfHeader {
        font-size: 32px;
        line-height: 38px;
        width: 100%;
        padding-bottom: 15px;
    }

    .hfParaf {
        font-size: 20px;
        line-height: 26px;
        width: 100%;
        width: 500px;
    }

    .hfBtn {
        width: 220px;
        height: 40px;
    }

    .firstSq {
        position: absolute;
        width: 525px;
        height: 390px;
        top: 107px;
        left:570px;
        gap: 0px;
        border-radius: 30px;
        border: 2px solid #0187A0;
        z-index: 1;
        left: 35;
    }
    
    .secondSq {
        position: absolute;
        width: 573px;
        height: 417px;
        top: 40px;
        left: 592px;
        gap: 0px;
        border-radius: 30px;
        background: #91C46C96;
        z-index: 2;
    }
    
    .thridSq {
        position: absolute;
        width: 610px;
        height: 415px;
        top: 10px;
        left: 618px;
        gap: 0px;
        border-radius: 13px;
        z-index: 3;
    }
    

}

@media only screen and (max-width: 1270px) {
    .hfContainer {
        background-color: white;
        display: flex;
        position: relative;
        height: 50vh;
        width: 100%;
    }
    .hfLeft {
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 300px;
        padding-left: 100px;
        position: absolute;
        top: 130px;
        left: 50px;
        padding-left: 50px;
    }
    

    .hfHeader {
        font-size: 32px;
        line-height: 38px;
        width: 100%;
        padding-bottom: 15px;
    }

    .hfParaf {
        font-size: 20px;
        line-height: 26px;
        width: 100%;
        width: 300px;
    }

    .hfBtn {
        width: 220px;
        height: 40px;
    }

    .firstSq {
        position: absolute;
        width: 425px;
        height: 390px;
        top: 107px;
        left:470px;
        gap: 0px;
        border-radius: 30px;
        border: 2px solid #0187A0;
        z-index: 1;
        left: 35;
    }
    
    .secondSq {
        position: absolute;
        width: 473px;
        height: 417px;
        top: 40px;
        left: 492px;
        gap: 0px;
        border-radius: 30px;
        background: #91C46C96;
        z-index: 2;
    }
    
    .thridSq {
        position: absolute;
        width: 510px;
        height: 415px;
        top: 10px;
        left: 518px;
        gap: 0px;
        border-radius: 13px;
        z-index: 3;
    }
    

}

@media only screen and (max-width: 1100px) {
    .hfContainer {
        background-color: white;
        display: flex;
        position: relative;
        height: 65vh;
        width: 100%;
    }
    .hfLeft {
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 300px;
        padding-left: 20px;
        position: absolute;
        top: 130px;
        left: 10px;
        padding-left: 50px;
    }
    

    .hfHeader {
        font-size: 32px;
        line-height: 38px;
        width: 100%;
        padding-bottom: 15px;
    }

    .hfParaf {
        font-size: 20px;
        line-height: 26px;
        width: 100%;
        width: 300px;
    }

    .hfBtn {
        width: 220px;
        height: 40px;
    }

    .firstSq {
        position: absolute;
        width: 345px;
        height: 390px;
        top: 107px;
        left:370px;
        gap: 0px;
        border-radius: 30px;
        border: 2px solid #0187A0;
        z-index: 1;
        left: 35;
    }
    
    .secondSq {
        position: absolute;
        width: 373px;
        height: 417px;
        top: 40px;
        left: 392px;
        gap: 0px;
        border-radius: 30px;
        background: #91C46C96;
        z-index: 2;
    }
    
    .thridSq {
        position: absolute;
        width: 380px;
        height: 415px;
        top: 10px;
        left: 418px;
        gap: 0px;
        border-radius: 13px;
        z-index: 3;
    }
    

}





@media only screen and (max-width: 700px) {
    .hfContainer {
        background-color: white;
        display: flex;
        position: relative;
        height: 100vh;
        width: 100%;
    }
    .hfLeft {
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 100%;
        padding-left: 100px;
        position: absolute;
        top: 40px;
        left: 0px;
        padding-left: 50px;
    }
    

    .hfHeader {
        font-size: 32px;
        line-height: 38px;
        width: 100%;
        padding-bottom: 15px;
    }

    .hfParaf {
        font-size: 20px;
        line-height: 26px;
        width: 100%;
    }

    .hfBtn {
        width: 220px;
        height: 40px;
    }

    .firstSq {
        position: absolute;
        width: 325px;
        height: 190px;
        top: 547px;
        left: 32px;
        gap: 0px;
        border-radius: 30px;
        border: 2px solid #0187A0;
        z-index: 1;
        left: 35;
    }
    
    .secondSq {
        position: absolute;
        width: 273px;
        height: 217px;
        top: 515px;
        left: 42px;
        gap: 0px;
        border-radius: 30px;
        background: #91C46C96;
        z-index: 2;
    }
    
    .thridSq {
        position: absolute;
        width: 310px;
        height: 215px;
        top: 503px;
        left: 63px;
        gap: 0px;
        border-radius: 13px 0px 0px 0px;
        z-index: 3;
    }
    
}
@media only screen and (max-width: 380px) {
    .hfContainer {
        background-color: white;
        display: flex;
        position: relative;
        height: 100vh;
        width: 100%;
    }
    .hfLeft {
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 90%;
        padding-left: 100px;
        position: absolute;
        top:60px;
        left: 0px;
        padding-left: 50px;
    }
    

    .hfHeader {
        font-size: 32px;
        line-height: 38px;
        width: 100%;
        padding-bottom: 15px;
    }

    .hfParaf {
        font-size: 20px;
        line-height: 26px;
        width: 100%;
    }

    .hfBtn {
        width: 220px;
        height: 40px;
    }

    .firstSq {
        position: absolute;
        width: 325px;
        height: 190px;
        top: 527px;
        left: 13px;
        gap: 0px;
        border-radius: 30px;
        border: 2px solid #0187A0;
        z-index: 1;
        left: 35;
    }
    
    .secondSq {
        position: absolute;
        width: 273px;
        height: 217px;
        top: 485px;
        left: 23px;
        gap: 0px;
        border-radius: 30px;
        background: #91C46C96;
        z-index: 2;
    }
    
    .thridSq {
        position: absolute;
        width: 310px;
        height: 215px;
        top: 473px;
        left: 44px;
        gap: 0px;
        border-radius: 13px;
        z-index: 3;
    }
    
}

