@media only screen and (max-width: 2100px) {
    .aboutP_container {
        height: 800px;
        position: relative;
        display: flex;
       justify-content: center;
    }
    
    .about_pic {
        width: 1027px;
        height: 638px;
        background: rgba(158, 17, 17, 0.5);
        border-radius: 15px;
        position: absolute;
        top: 50px;
        left: 340px;
    }
    
    .aboutSq {
        position: absolute;
        width: 968px;
        height: 576px;
        top: 151px;
        left: 260px;
        gap: 0px;
        border-radius: 30px;
        border: 2px solid #0187A0;
    }
    }
    

@media only screen and (max-width: 1400px) {
.aboutP_container {
    height: 800px;
    position: relative;
    display: flex;
   justify-content: center;
}

.about_pic {
    width: 1027px;
    height: 638px;
    background: rgba(158, 17, 17, 0.5);
    border-radius: 15px;
    position: absolute;
    top: 80px;
    left: 140px;
}

.aboutSq {
    position: absolute;
    width: 968px;
    height: 576px;
    top: 181px;
    left: 60px;
    gap: 0px;
    border-radius: 30px;
    border: 2px solid #0187A0;
}
}

@media only screen and (max-width: 1200px) {
    .aboutP_container {
        height: 500px;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .about_pic {
        width: 640px;
        height: 338px;
        background: rgba(158, 17, 17, 0.5);
        position: absolute;
        top: 10px;
        left: 185px;
        border-radius: 15px;
    }
    .aboutSq {
        position: absolute;
        width: 610px;
        height: 276px;
        top: 105px;
        left: 135px;
        gap: 0px;
        border-radius: 30px;
        border: 2px solid #0187A0;
    }

}


@media only screen and (max-width: 480px) {
    .aboutP_container {
        height: 500px;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .about_pic {
        width: 340px;
        height: 338px;
        background: rgba(158, 17, 17, 0.5);
        position: absolute;
        top: 10px;
        left: 35px;
        border-radius: 15px;
    }
    .aboutSq {
        position: absolute;
        width: 310px;
        height: 276px;
        top: 105px;
        left: 15px;
        gap: 0px;
        border-radius: 30px;
        border: 2px solid #0187A0;
    }
    
    
}