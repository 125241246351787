.footer {
  width: 100%;
  background-color: #1C344D;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 40px 20px;
  gap: 40px;
  position: relative;
}

.footerWrapper {
  width: 95%;
  background-color: #1C344D;
  display: flex;
  align-items: center;
  padding: 40px 20px;
  gap: 40px;
}

.logoFooter {
  height: 87px;
  width: 97px;
  position: absolute;
  top: 40px;
  left: 80px;
}

.copyF {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color: #C6E070;
  top: 50px;
  left: 180px;
  width: 350px;
}

.copyPf {
  margin-top: 20px;
}

.logoF,
.links,
.socialF {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.links {
  flex-direction: column;
  gap: 15px;
}

.links a {
  color: white;
  text-decoration: none;
  font-size: 16px;
}

.links a:hover {
  color: #88c3ea;
}

.socialF {
  flex-direction: column;
  gap: 20px;
}

.socialF a {
  color: white;
}

.socIcons {
  display: flex;
  gap: 15px;
}

.socIcons a {
  color: white;
  text-decoration: none;
  font-size: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.socIcons a svg {
  margin-right: 10px;
  font-size: 24px;
}

.socIcons a:hover {
  color: #ddd;
}

.socIcon {
  margin-right: 10px;
}

@media (max-width: 1024px) {
  .footerWrapper {
    flex-direction: column;
    height: auto;
    padding: 20px;
    position: static;
  }

  .logoFooter, 
  .copyF {
    position: static;
    margin: 0 auto;
  }

  .copyF {
    width: auto;
    text-align: center;
    margin-top: 20px;
  }

  .logoF, 
  .links, 
  .socialF {
    flex-direction: column;
    gap: 20px;
    align-items: center;
  }

  .links {
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
  }

  .socIcons {
    justify-content: center;
  }
}

@media (max-width: 768px) {
  .footerWrapper {
    padding: 20px 10px;
  }

  .logoFooter {
    height: 50px;
    width: 50px;
  }

  .links a {
    font-size: 14px;
  }

  .socIcons a svg {
    margin-right: 5px;
    font-size: 20px;
  }

  .copyPf {
    font-size: 14px;
  }
}

@media (max-width: 480px) {
  .footerWrapper {
    padding: 20px 5px;
  }

  .socialF, 
  .links, 
  .socIcons {
    flex-direction: column;
    gap: 10px;
    align-items: center;
  }

  .logoFooter,
  .copyF {
    margin-bottom: 20px;
  }
}
