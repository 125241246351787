.wds_container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 50px 0;
    padding: 20px;
}

.wdsMain {
    width: 100%;
    max-width: 1479px;
    height: auto;
    display: flex;
    flex-direction: row-reverse;
    justify-content: center;
    align-items: center;
    background: #F1F2F2;
    padding: 20px;
    border-radius: 30px;
}

.wds_img {
    width: 1100px;
    margin-right: 20px;
    display: flex;
    justify-content: flex-end;
}

.wdsPic {
    width: 800px;
    height: 340px;
    border-radius: 30px;
    
}

.wds_info {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    width: 100%;
    margin-left: 120px;
}

.wds_info h2 {
    font-family: Poppins;
    font-size: 40px;
    font-weight: 700;
    line-height: 40px;
    text-align: left;
    color: #1C344D;
    margin-bottom: 20px;
}

.wds_info p {
    font-family: Poppins;
    font-size: 30px;
    font-weight: 300;
    line-height: 40px;
    text-align: left;
    margin: 0;
}

.wdsBtn {
    width: 175px;
    height: 48px;
    border-radius: 50px;
    font-family: Poppins;
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: 0.1px;
    text-align: center;
    margin-top: 40px;
    background: #C6E070;
}

@media (max-width: 1008px) {
    .wdsPic {
        width: 100%;
        max-width: 400px;
        margin-left: 0;
    }

    .wds_info {
        width: 100%;
        max-width: none;
        padding-left: 0;
        margin-left: 0;
    }

    .wds_info h2 {
        font-size: 30px;
        line-height: 36px;
    }

    .wds_info p {
        font-size: 20px;
        line-height: 28px;
    }

    .wdsBtn {
        width: 150px;
        height: 40px;
        font-size: 14px;
    }
}

@media (max-width: 480px) {
    .wds_img {
       padding-left: 20px;
    }
    .wdsPic {
        width: 100%;
        max-width: 300px;
        height: 220px;
    }

    .wds_info {
        width: 100%;
        padding-left: 10px;
        margin-left: 0;
    }

    .wds_info h2 {
        font-size: 24px;
        line-height: 32px;
    }

    .wds_info p {
        font-size: 16px;
        line-height: 24px;
    }

    .wdsBtn {
        width: 120px;
        height: 36px;
        font-size: 12px;
    }
}
