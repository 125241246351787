@media (max-width: 2100px) {
    .servicePic_container {
        width: 100%;
        height: 991px;
        position: relative;
    }
    
    .service_Img {
        position: absolute;
        width: 1127px;
        height: 638px;
        top: 21px;
        left: 437px;
        border-radius: 15px;
    }
    }
    
    .serviceImg_sq {
        position: absolute;
        width: 1068px;
        height: 576px;
        top: 141px;
        left: 350px;
        border-radius: 30px;
        border: 2px solid #0187A0;
    }
    



@media (max-width: 1768px) {
    .servicePic_container {
        width: 100%;
        height: 991px;
        position: relative;
    }

    .service_Img {
        position: absolute;
        width: 1127px;
        height: 638px;
        top: 21px;
        left: 287px;
        border-radius: 15px;
    }
    .serviceImg_sq {
        position: absolute;
        width: 1068px;
        height: 576px;
        top: 141px;
        left: 130px;
        border-radius: 30px;
        border: 2px solid #0187A0;

    }
    }


@media (max-width: 1368px) {
    .service_Img {
        position: absolute;
        width: 597px;
        height: 638px;
        top: 21px;
        left: 150px;
        border-radius: 15px;
    }
    .serviceImg_sq {
        position: absolute;
        width: 625px;
        height: 556px;
        top: 141px;
        left: 80px;
        border-radius: 30px;
        border: 2px solid #0187A0;
    }
}





@media (max-width: 480px) {
    .servicePic_container {
        width: 100%;
        height: 550px;
        position: relative;
    }
    .service_Img {
        position: absolute;
        width: 330px;
        height: 338px;
        top: 20px;
        left:30px;
        border-radius: 15px;
    }
    
    .serviceImg_sq {
        position: absolute;
        width: 281px;
        height: 276px;
        top: 100px;
        left: 10px;
        gap: 0px;
        border-radius: 30px;
        border: 2px solid #0187A0;
    
    }
    
    
}