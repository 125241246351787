.hero_Container {
    position: relative;
    justify-content: center;
    align-items: center;
   
    background-size: cover;
    background-position: center;
    color: white;
    width: 100%;
  }
  
  .text_Hero {
    position: absolute;
    top: 35%;
    right: 6%;
    text-align: center;
    color: white;
    width: 45%;
  }
  
  .text_Hero h1 {
    font-weight: 900;
    opacity: 1;
    color: white;
    font-size: 50px;
  }
  
  .bgPicMain {
    height: 100%;
    width: 100%;
    z-index: -1;
    filter: blur(2px);
  }
  
  .text_Hero h3 {
    color: white;
    padding-top: 10%;
    font-size: x-large;
  }


  
  @media only screen and (max-width: 768px) {
    .text_Hero {
      top: 30%; 
      right: 0;
      left: 0; 
      width: 80%; 
    }
  
    .text_Hero h1 {
      font-size: 40px; 
    }
  
    .text_Hero h3 {
      font-size: large; 
    }
  }
  