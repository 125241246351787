* {
    margin: 0;
    box-sizing: border-box; 
}

.services {
    width: 100%;
    min-height: 893px;
    gap: 0px;
    background: #1C344D;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px;
    position: relative;
}

.serviceHeader {
    color: #C6E070;
    padding-bottom: 50px;
    text-align: center;
    font-size: 2.5rem;
}

.services__item {
    height: 500px;
    background: #FBFDF7;
    border-radius: 25px;
    padding: 20px;
    text-align: center;
    cursor: pointer;
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    transition: transform 0.3s ease-in-out;
}

.services__item:hover {
    transform: scale(1.05);
}


.services__title {
    font-weight: bold;
    font-size: 2rem;
    color: #0187A0;
    margin-bottom: 20px;
}

.service_item-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 2rem;
    width: 100%;
    max-width: 1515px;
    flex-wrap: wrap;
    position: relative;
}

.imgp {
    width: 400px;
    max-width: 300px;
    height: 200px;
    border-radius: 15px;
    animation: slideInFromLeft 2.5s ease-in-out forwards;
    opacity: 1;
    margin-bottom: 20px;
}

.item_det {
    width: 100%;
    max-width: 260px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
}

.serBtn {
    border-radius: 50px;
    height: 48px;
    width: 175px;
    color: #0187A0;
    border: 2px solid #0187A0;
    background-color: transparent;
    position: absolute;
    top: 170px;
    left: 10px;
}

.arrowService {
    padding: 10px;
    color: #0187A0;
    border-radius: 50px;
    border: 2px solid #0187A0;
    margin-top: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 48px;
    height: 48px;
    margin-left: 10px;
    position: absolute;
    top: 160px;
    left: 200px;
    
}
.btnIcon {
    display: flex;
    justify-content: space-between;
}

@media only screen and (max-width: 776px) {
    .service_item-wrapper {
        flex-direction: column;
        align-items: center;
    }
    .imgp {
        width: 70%;
        height: auto;
    }
}

@media only screen and (min-width: 777px) and (max-width: 1370px) {
    .service_item-wrapper {
        display: grid;
        grid-template-columns: repeat(2, 1fr); 
        gap: 2rem;
    }
}

